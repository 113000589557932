import Papa from "papaparse"

import { Country, Matchup, PricepoolEntry, Row } from "./types"

const URL =
  "https://docs.google.com/spreadsheets/d/1f_U007Z827MQe7Si1mhgvAGc5dTsOrzItG1R7zHYnSM/gviz/tq?tqx=out:csv"
export async function fetchData() {
  // @ts-ignore
  if (window.dataKludge) {
    // @ts-ignore
    return window.dataKludge
  }
  // @ts-ignore
  return (window.dataKludge = {
    matchups: await fetchFromSheets("matchups"),
    countries: await fetchFromSheets("countries"),
    rows: await fetchFromSheets("rows"),
    pricepool: await fetchFromSheets("pricepool"),
  } as any as {
    matchups: Matchup[]
    countries: Country[]
    rows: Row[]
    pricepool: PricepoolEntry[]
  })
}

async function fetchFromSheets(sheet: string) {
  const raw = await (await fetch(URL + `&sheet=${sheet}`)).text()
  const { data } = await Papa.parse(raw)
  return parseData(data)
}

function parseData(data: any[]) {
  console.log(data)
  const stringData = data.map(r => (r as string[]).map(removeQuotes))
  return stringData
    .slice(1)
    .map((row, index) => toEntry(row, stringData[0], index))
}
function removeQuotes(cell: string) {
  if (cell.startsWith('"')) cell = cell.substring(1, cell.length - 1)
  return cell.trim()
}
function toEntry(row: any[], keys: string[], index: number) {
  const ret: Record<string, string | number> = {}
  row.forEach((v, i) => {
    if (v)
      ret[keys[i].trim()] = isNumberField(keys[i].trim())
        ? parseFloat(v.trim())
        : keys[i].trim() === "date"
        ? new Date(Date.parse(v.trim()))
        : v.trim()
  })
  ret.index = index
  return ret
}
function isNumberField(key: string) {
  switch (key) {
    case "oddsHome":
      return true
    case "oddsDraw":
      return true
    case "oddsAway":
      return true
    case "multiplier":
      return true
    default:
      return false
  }
}
